// Navbar
.navbar {
    --bs-navbar-toggler-focus-width: 0;
    --bs-navbar-toggler-padding-x: 8px;
    --bs-navbar-toggler-padding-y: 12px;
    --bs-navbar-padding-y: 10px;
    --bs-navbar-nav-link-padding-x: 20px;
    --bs-navbar-color: #201F24;
    --bs-navbar-hover-color: #201F24;
    &.sticky-navbar {
        --bs-navbar-padding-y: 6px;
        // background-color: transparent;
    }
    // @extend .transition-1;
    @media screen and (max-width: 479px) {
        .btn-primary {
            --bs-btn-padding-x: 10px;
            --bs-btn-padding-y: 6px;
            // background-image: linear-gradient(149.3deg, #02DB5B -29.94%, #4977C1 135.03%);
        }
    }
}

.navbar-toggler {
    --bs-border-width: 0;
    &:hover {
        .line {
            &:last-child {
                width: 24px;
            }
        }
    }
    .line {
        @extend .d-block;
        @extend .transition-1;
        background-color: var(--bs-body-color);
        height: 2px;
        width: 24px;
        &:not(:last-child) {
            margin-bottom: 5px;
        }
        &:last-child {
            width: 18px;
            margin-left: auto;
        }
    }
}

// Navbar Brand x Logo
.navbar {
    @include sm {
        padding: 10px 14px;
    }
}

.navbar-brand {
    max-width: 240px;
    @include sm {
        max-width: 150px;
        & img {
            height: auto !important;
        }
    }
}

.notify {
    background-color: #333339;
    border: 1px solid #42424A;
    border-radius: 16px;
    width: 360px;
    margin-top: 16px;
    margin-right: -24px;
    @include sm {
        width: 290px;
        margin-right: -50px;
    }
    &::before {
        content: "";
        border-width: 20px;
        border-style: solid;
        border-color: transparent;
        border-bottom-color: #42424A;
        position: absolute;
        right: 24px;
        bottom: calc(100% - 5px);
        @include sm {
            right: 48px;
        }
    }
    &-header {
        padding: 16px;
        background-color: #42424A;
        border-radius: 16px 16px 0 0;
        & h4 {
            font-size: 20px;
            line-height: auto;
            font-weight: 600;
            color: $white;
            @include mmd {
                font-size: 16px;
            }
        }
        & button {
            font-size: 12px;
            line-height: auto;
            font-weight: 500;
            color: #61C57B;
        }
    }
    &-content {
        max-height: 300px;
        padding: 16px;
        @include custom-scroll(4px)
    }
    &-item {
        --width: 48px;
        cursor: pointer;
        border-top: 1px solid #42424A;
        @include sm {
            --width: 36px;
        }
        &:first-child {
            padding-top: 0 !important;
            border: none !important;
        }
        &-img {
            width: var(--width);
            height: var(--width);
            flex: 0 0 auto;
            @include img(cover);
        }
        &-content {
            width: calc(100% - var(--width));
            flex: 0 0 auto;
            padding-left: 1rem;
            & p {
                font-size: 16px;
                line-height: 120%;
                font-weight: 600;
                color: #F7FFFA;
                word-break: break-all;
                // @include text_ellipsis(2);
                @include sm {
                    font-size: 14px;
                }
            }
        }
    }
}