.theme-hero {
    padding-top: 100px;
    padding-bottom: 50px;
    background-color: #201F24;
    background-image: url('../../../img/regular/herobg.svg');
    & .container-hero {
        @include sm {
            padding: 0 20px !important;
        }
    }
    & h1 {
        // font-size: 96px;
        @include xl {
            font-size: 64px;
        }
        @include lg {
            font-size: 60px;
        }
        @include md {
            font-size: 60px;
        }
        @include sm {
            font-size: 42px;
        }
        @include ssm {
            font-size: 36px;
        }
    }
    &-text {
        &1 {
            @include lg {
                font-size: 24px;
            }
            @include md {
                font-size: 22px !important;
            }
            @include sm {
                font-size: 20px !important;
            }
            @include ssm {
                font-size: 18px !important;
            }
        }
        &2 {
            @include lg {
                margin-top: 30px !important;
                font-size: 22px !important;
            }
            @include md {
                margin-top: 24px !important;
                font-size: 20px !important;
            }
            @include sm {
                margin-top: 14px !important;
                font-size: 16px !important;
            }
        }
    }
    & .social-buttons {
        @include md {
            width: 49% !important;
        }
    }
    &-link {
        @include md {
            font-size: 24px !important;
        }
        @include sm {
            font-size: 22px !important;
        }
    }
    &-socialIcon {
        @include md {
            width: 30px !important;
            height: auto !important;
        }
        @include sm {
            width: 24px !important;
            height: auto !important;
        }
    }
    .MuiTypography-root {
        &.MuiTypography-h3 {
            &.css-16xon5a-MuiTypography-root {
                @include xl {
                    font-size: 60px;
                }
                @include lg {
                    font-size: 50px;
                }
                @include md {
                    font-size: 44px;
                }
                @include sm {
                    font-size: 38px;
                }
            }
        }
    }
}

@media (max-width: 991.98px) {
    .custom-height {
        min-height: 0vh;
        padding-top: 100px;
    }
}

.theme-about {
    & .swiper-slide {
        & p {
            @include md {
                font-size: 24px !important;
            }
            @include sm {
                font-size: 20px !important;
            }
        }
        @include md {
            font-size: 24px !important;
        }
        @include sm {
            font-size: 20px !important;
            padding: 20px;
        }
    }
}

.products {
    &-inner {
        @include commonPadding;
        @include sm {
            padding-left: 20px;
            padding-right: 20px;
        }
    }
    & .token-creator {
        @include md {
            font-size: 28px !important;
        }
        @include sm {
            font-size: 24px !important;
        }
    }
    & .develop-custom-tokens {
        @include md {
            font-size: 22px !important;
        }
        @include sm {
            font-size: 18px !important;
        }
    }
    &-title {
        margin-top: 104px;
        @include xl {
            margin-top: 80px;
        }
        @include lg {
            margin-top: 70px;
        }
        @include md {
            margin-top: 60px;
        }
        @include sm {
            margin-top: 30px;
        }
    }
}

.tokenomics {
    overflow: hidden;
    @include commonPadding;
    & .section-title {
        @include sm {
            padding: 0 12px;
        }
    }
    & .tk-text {
        & .pre-title {
            @include font-64;
        }
    }
}