.dashboard-wrap {
    width: calc(100% - 248px - var(--extra, 50px));
    margin-left: auto;
    flex: 0 0 auto;
    padding-right: 40px;
    @include lg {
        --extra: 30px;
        margin-top: 40px;
    }
    @media screen and (max-width: 991px) {
        width: 100%;
        margin-left: 0;
        padding-right: 12px;
        padding-left: 14px;
        margin-top: 40px;
        overflow: hidden;
    }
    .card-seciton {
        .stake-card {
            padding: 20px 16px;
            border-radius: 16px;
            border: 1px solid #61C57B;
            background: #201F24;
            .card-header {
                color: $white2;
                font-size: 16px;
                font-weight: 700;
                line-height: 150%;
            }
            &-img {
                width: 100%;
                height: 261px;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .card-title {
                color: $white2;
                font-size: 15px;
                font-weight: 700;
                line-height: 150%;
                margin-top: 10px;
                margin-bottom: 12px;
            }
            .des {
                color: $white2;
                font-size: 15px;
                font-weight: 700;
                line-height: 150%;
                margin-bottom: 14px;
            }
            .bar {
                width: 100%;
                height: 12px;
                border-radius: 32px;
                background-color: #333339;
                &-line {
                    height: 100%;
                    width: 25%;
                    border-radius: 32px;
                    background-color: #60C37C;
                }
            }
            .btn1,
            .btn2 {
                border: double 1px rgba(0, 0, 0, 0) !important;
                background-image: linear-gradient(#333339, #333339), linear-gradient(to right, #61C57B 0%, #5993A4 100%);
                background-origin: border-box;
                background-clip: content-box, border-box;
                border-radius: 8px;
                margin-top: 10px;
                padding: 0.3px;
            }
            .btn {
                color: $white;
                font-size: 10px;
                font-weight: 700;
                line-height: 150%;
                padding: 8px 0;
                background: #333339 !important;
            }
        }
    }
    .nav {
        border-bottom: 0px;
        border-radius: 16px;
        border: 1px solid #42424A;
        max-width: max-content;
    }
    .nav-tabs .nav-link {
        color: $text;
        font-size: 16px;
        line-height: 150%;
        padding: 8px 60px;
        @include lg {
            padding: 8px 40px;
        }
        @include sm {
            padding-left: 1rem;
            padding-right: 1rem;
        }
    }
    .nav-tabs .nav-link.active,
    .nav-tabs .nav-item.show .nav-link {
        background-color: #42424A !important;
        border-color: transparent !important;
        border-radius: 10px !important;
        color: $white2 !important;
    }
    .nav-tabs .nav-link:hover,
    .nav-tabs .nav-link:focus {
        isolation: isolate;
        border-color: transparent;
    }
}

.cbd-sidebar {
    position: fixed;
    top: 67px;
    left: 0;
    height: calc(100vh - 67px);
    width: 248px;
    z-index: 99;
    padding-top: 50px;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow: -moz-scrollbars-none;
    background-color: #2C2C31;
    @include mmd {
        width: 330px;
        background-color: #201F24;
        padding-top: 0;
    }
    &::after {
        content: "";
        border-radius: 487px;
        opacity: 0.5;
        background-color: #26C773;
        filter: blur(50px);
        width: 16px;
        height: 488px;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }
    &::-webkit-scrollbar {
        width: 0 !important;
    }
    @media screen and (max-width: 991px) {
        transform: translateX(-100%);
        transition: all .25s linear;
        &.show {
            transform: translateX(0);
        }
    }
    .label {
        margin-bottom: 8px;
    }
    & .sidebar {
        &-line {
            margin-top: 24px;
            width: calc(100% - 58px);
            margin-left: auto;
            margin-right: 24px;
            height: 1px;
            border-top: 1px solid #42424A;
            margin-bottom: 50px;
        }
        &-meta {
            font-size: 14px;
            color: $text;
            margin-bottom: 16px;
            padding-left: 30px;
        }
        &-social {
            padding: 0 20px;
            & li {
                &:not(:last-child) {
                    margin-bottom: 8px;
                }
                & a {
                    font-size: 16px;
                    color: #a3a3a3;
                    padding: 8px 10px;
                    border-radius: 4px;
                    transition: all .3s ease-in-out;
                    &:hover {
                        color: $primary;
                    }
                }
            }
        }
        &-footer {
            padding: 0 34px;
            padding-bottom: 16px;
            & a {
                text-decoration: underline;
                color: #60C37C;
                font-size: 14px;
                font-weight: 500;
                line-height: 150%;
            }
        }
        &-list {
            margin: 0px;
            padding: 0px;
            list-style: none;
            display: flex;
            flex-direction: column;
            gap: 8px;
            li {
                a {
                    display: flex;
                    align-content: center;
                    gap: 12px;
                    font-size: 16px;
                    color: #A3A3A3;
                    padding: 8px 30px;
                    position: relative;
                    z-index: 1;
                    &::before {
                        content: "";
                        width: 6px;
                        height: 27px;
                        position: absolute;
                        top: 50%;
                        left: 0;
                        transform: translateY(-50%);
                        border-radius: 0px 8px 8px 0px;
                        background-color: $primary;
                        box-shadow: 0.5px 0.5px 8px 0px $primary;
                        transition: all .3s ease-in-out;
                        opacity: 0;
                        visibility: hidden;
                    }
                    &:hover,
                    &.active-dash {
                        color: $primary;
                        font-weight: 500;
                        &::before {
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                    & .icon {
                        --width: 20px;
                        height: var(--width);
                        width: var(--width);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }
        }
    }
}

.sidebar {
    &-mobile {
        padding: 24px;
        padding-left: 30px;
        &>div {
            border: 1px solid #42424A;
            border-radius: 100px;
            padding: 8px;
        }
        // & .lang {
        //     padding-left: 34px;
        //     position: relative;
        //     &::after {
        //         content: url("data:image/svg+xml;charset=UTF-8,%3csvg width='21' height='21' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='20.7692' height='20.7692' rx='10.3846' fill='%2342424A'/%3e%3cg clip-path='url(%23clip0_4049_6140)'%3e%3cpath d='M5.38477 10.3846C5.38477 11.0412 5.51409 11.6914 5.76537 12.298C6.01664 12.9046 6.38494 13.4558 6.84923 13.9201C7.31352 14.3844 7.86472 14.7527 8.47135 15.004C9.07798 15.2553 9.72816 15.3846 10.3848 15.3846C11.0414 15.3846 11.6916 15.2553 12.2982 15.004C12.9048 14.7527 13.456 14.3844 13.9203 13.9201C14.3846 13.4558 14.7529 12.9046 15.0042 12.298C15.2554 11.6914 15.3848 11.0412 15.3848 10.3846C15.3848 9.0585 14.858 7.78673 13.9203 6.84905C12.9826 5.91137 11.7108 5.38458 10.3848 5.38458C9.05868 5.38458 7.78691 5.91137 6.84923 6.84905C5.91155 7.78673 5.38477 9.0585 5.38477 10.3846Z' stroke='%23A3A3A3' stroke-width='0.555556' stroke-linecap='round' stroke-linejoin='round'/%3e%3cpath d='M5.71777 8.7179H15.0511' stroke='%23A3A3A3' stroke-width='0.555556' stroke-linecap='round' stroke-linejoin='round'/%3e%3cpath d='M5.71777 12.0513H15.0511' stroke='%23A3A3A3' stroke-width='0.555556' stroke-linecap='round' stroke-linejoin='round'/%3e%3cpath d='M10.1069 5.38458C9.17099 6.88437 8.6748 8.61673 8.6748 10.3846C8.6748 12.1524 9.17099 13.8848 10.1069 15.3846' stroke='%23A3A3A3' stroke-width='0.555556' stroke-linecap='round' stroke-linejoin='round'/%3e%3cpath d='M10.6621 5.38458C11.598 6.88437 12.0942 8.61673 12.0942 10.3846C12.0942 12.1524 11.598 13.8848 10.6621 15.3846' stroke='%23A3A3A3' stroke-width='0.555556' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/g%3e%3cdefs%3e%3cclipPath id='clip0_4049_6140'%3e%3crect width='12.9808' height='12.9808' fill='white' transform='translate(3.89453 3.89423)'/%3e%3c/clipPath%3e%3c/defs%3e%3c/svg%3e ");
        //         position: absolute;
        //         top: 50%;
        //         left: 8px;
        //         transform: translateY(-50%);
        //         margin-top: 3px;
        //     }
        //     & a {
        //         border: none;
        //         font-size: 14px;
        //         color: $white !important;
        //         &::after {
        //             display: none;
        //         }
        //         &::before {
        //             content: url("data:image/svg+xml;charset=UTF-8,%3csvg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M1 1.38599L5 5.38599L9 1.38599' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e ");
        //             position: absolute;
        //             top: 50%;
        //             transform: translateY(-50%);
        //             right: 8px;
        //             margin-top: -2px;
        //         }
        //     }
        //     & .dropdown {
        //         &>div {
        //             width: 100%;
        //             margin-top: 10px;
        //         }
        //     }
        // }
    }
}

.lang {
    padding-left: 34px !important;
    position: relative;
    .cbd-navbar & {
        border: 1px solid #42424A;
        border-radius: 100px;
        padding: 8px;
        min-width: 90px;
    }
    &::after {
        content: url("data:image/svg+xml;charset=UTF-8,%3csvg width='21' height='21' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='20.7692' height='20.7692' rx='10.3846' fill='%2342424A'/%3e%3cg clip-path='url(%23clip0_4049_6140)'%3e%3cpath d='M5.38477 10.3846C5.38477 11.0412 5.51409 11.6914 5.76537 12.298C6.01664 12.9046 6.38494 13.4558 6.84923 13.9201C7.31352 14.3844 7.86472 14.7527 8.47135 15.004C9.07798 15.2553 9.72816 15.3846 10.3848 15.3846C11.0414 15.3846 11.6916 15.2553 12.2982 15.004C12.9048 14.7527 13.456 14.3844 13.9203 13.9201C14.3846 13.4558 14.7529 12.9046 15.0042 12.298C15.2554 11.6914 15.3848 11.0412 15.3848 10.3846C15.3848 9.0585 14.858 7.78673 13.9203 6.84905C12.9826 5.91137 11.7108 5.38458 10.3848 5.38458C9.05868 5.38458 7.78691 5.91137 6.84923 6.84905C5.91155 7.78673 5.38477 9.0585 5.38477 10.3846Z' stroke='%23A3A3A3' stroke-width='0.555556' stroke-linecap='round' stroke-linejoin='round'/%3e%3cpath d='M5.71777 8.7179H15.0511' stroke='%23A3A3A3' stroke-width='0.555556' stroke-linecap='round' stroke-linejoin='round'/%3e%3cpath d='M5.71777 12.0513H15.0511' stroke='%23A3A3A3' stroke-width='0.555556' stroke-linecap='round' stroke-linejoin='round'/%3e%3cpath d='M10.1069 5.38458C9.17099 6.88437 8.6748 8.61673 8.6748 10.3846C8.6748 12.1524 9.17099 13.8848 10.1069 15.3846' stroke='%23A3A3A3' stroke-width='0.555556' stroke-linecap='round' stroke-linejoin='round'/%3e%3cpath d='M10.6621 5.38458C11.598 6.88437 12.0942 8.61673 12.0942 10.3846C12.0942 12.1524 11.598 13.8848 10.6621 15.3846' stroke='%23A3A3A3' stroke-width='0.555556' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/g%3e%3cdefs%3e%3cclipPath id='clip0_4049_6140'%3e%3crect width='12.9808' height='12.9808' fill='white' transform='translate(3.89453 3.89423)'/%3e%3c/clipPath%3e%3c/defs%3e%3c/svg%3e ");
        position: absolute;
        top: 50%;
        left: 8px;
        transform: translateY(-50%);
        margin-top: 3px;
    }
    & a {
        border: none;
        font-size: 14px;
        color: $white !important;
        &.dropdown-item::before {
            display: none;
        }
        &::after {
            display: none;
        }
        &::before {
            content: url("data:image/svg+xml;charset=UTF-8,%3csvg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M1 1.38599L5 5.38599L9 1.38599' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e ");
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 8px;
            margin-top: -2px;
        }
    }
    & .dropdown {
        &>div {
            width: 100%;
            margin-top: 10px;
        }
    }
}

@media screen and (max-width: 1000px) {
    .sidebar-list {
        gap: 10px;
    }
}