// .arrow-container {
//     position: relative;
//     top: 52%;
//     transform: translateY(-52%);
// }
// .arrow-container img {
//     margin-top: -2px;
//     border: 3px solid #1f3c4a;
//     border-radius: 10px;
//     padding: inherit;
//     background-color: rgb(37, 169, 142);
// }
// .enpFfZ {
//     position: relative;
// }
.iUsjHb {
    border-radius: 8px;
}

.hhoFBL {
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.morphism {
    background: rgba(66, 90, 112, 0.3);
    box-shadow: 0px 21.36px 26.7px 0px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(10px);
}

.currency-input-new {
    border-radius: 10px !important;
    position: relative;
    width: 50%;
    outline: transparent !important;
    border: none;
    background-color: transparent;
    padding-left: 2px;
    font-size: 20px;
    font-weight: 500;
    font-family: "DM Sans";
    color: #f1f1f1;
    filter: none;
    opacity: 1;
    transition: opacity 250ms ease-in-out 0s;
    text-align: left;
    font-size: 36px;
    font-weight: 485;
    max-height: 44px;
}

.we {
    position: relative;
    border-radius: 99px;
    background: rgba(5, 44, 76, 1);
    // / border: 0.4px solid #646464; /
    box-sizing: border-box;
    padding: 5px 10px;
    text-align: center;
    font-size: 14px;
    color: #fff;
    font-family: "DM Sans";
}

.balance {
    color: rgba(181, 181, 181, 1);
    margin-left: auto;
    font-size: 16px;
    @media screen and (max-width: 1000px) {
        font-size: 24px;
    }
}

.max {
    -webkit-background-clip: text;
    background-clip: text;
    font-size: 16px;
    font-weight: 700;
    color: "white";
}

.ws {
    position: relative;
    border-radius: 99px;
    background-color: rgba(35, 105, 116, 1);
    // / border: 0.4px solid #646464; /
    box-sizing: border-box;
    padding: 5px 10px;
    text-align: center;
    font-size: 14px;
    color: #fff;
    font-family: "DM Sans";
}

//SWAP END
.css-tgsonj {
    display: none;
}

css-1gfbuk-MuiDataGrid-root .MuiDataGrid-container--top [role=row],
.css-1gfbuk-MuiDataGrid-root .MuiDataGrid-container--bottom [role=row] {
    background-color: rgb(29, 42, 42) !important;
}

.css-121xgsf-MuiDataGrid-root {
    --unstable_DataGrid-radius: 4px;
    --unstable_DataGrid-headWeight: 500;
    --unstable_DataGrid-overlayBackground: rgba(255, 255, 255, 0.38);
    --DataGrid-containerBackground: #fff;
    --DataGrid-pinnedBackground: #fff;
    --DataGrid-rowBorderColor: rgb(29, 42, 42) !important;
    --DataGrid-cellOffsetMultiplier: 2;
    --DataGrid-width: 0px;
    --DataGrid-hasScrollX: 0;
    --DataGrid-hasScrollY: 0;
    --DataGrid-scrollbarSize: 10px;
    --DataGrid-rowWidth: 0px;
    --DataGrid-columnsTotalWidth: 0px;
    --DataGrid-leftPinnedWidth: 0px;
    --DataGrid-rightPinnedWidth: 0px;
    --DataGrid-headerHeight: 0px;
    --DataGrid-headersTotalHeight: 0px;
    --DataGrid-topContainerHeight: 0px;
    --DataGrid-bottomContainerHeight: 0px;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    box-sizing: border-box;
    position: relative;
    border-width: 1px;
    border-style: solid;
    // border-color: rgba(224, 224, 224, 1);
    border-radius: var(--unstable_DataGrid-radius);
    color: rgba(0, 0, 0, 0.87);
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    outline: none;
    height: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    min-width: 0;
    min-height: 0;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    overflow-anchor: none;
    background-color: rgb(39, 39, 41);
    color: gray;
}

// basic table design start 
.table-responsive .table tbody tr:hover {
    background: linear-gradient(180deg, rgba(217, 217, 217, 0.25) 0%, rgba(217, 217, 217, 0.25) 100%), linear-gradient(0deg, rgba(9, 27, 25, 0.7), rgba(9, 27, 25, 0.7)), linear-gradient(90deg, rgba(2, 219, 91, 0.2) 0%, rgba(73, 119, 193, 0.2) 100%);
    border: 1px solid #02DB5B !important;
    //  border: 1px #02DB5B
}

// basic table design start 
// Setting design start
.trendswapx {
    position: relative;
    font-weight: 500;
    color: #fff;
}

.when-available-aggregates {
    margin: 0;
    font-weight: 500;
}

.and-gas-free {
    font-weight: 500;
}

.learn-more {
    font-family: "DM Sans";
    color: rgb(64, 252, 254);
    font-weight: 600;
}

.and-gas-free-swaps-learn-more {
    margin: 0;
}

.when-available-aggregates-container {
    position: relative;
    font-size: 12px;
    text-align: left;
}

.trendswapx-parent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.toggle-button-child {
    width: 20px;
    position: relative;
    border-radius: 50%;
    background-color: #131313;
    height: 20px;
}

.toggle-button {
    width: 52.33px;
    border-radius: 99px;
    background-color: #353535;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 4px;
    box-sizing: border-box;
}

.active .toggle-button-child {
    transform: translateX(20px);
    /* Move button to the right */
}

.setting-parent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 25px;
}

.max-slippage {
    position: relative;
    font-weight: 500;
}

.octiconquestion-24 {
    width: 20px;
    position: relative;
    height: 20px;
    overflow: hidden;
    flex-shrink: 0;
}

.max-slippage-parent {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 8px;
}

.auto {
    position: relative;
}

.component-3-icon {
    width: 16px;
    position: relative;
    height: 16px;
    overflow: hidden;
    flex-shrink: 0;
}

.auto-parent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 7px;
    color: #f1f1f1;
}

.frame-container {
    align-self: stretch;
    border-top: 0.7px solid #9b9797;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0px;
}

.frame-group {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: left;
    font-size: 12px;
}

.setting {
    position: absolute;
    border-radius: 16px;
    background-color: #131313;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    box-sizing: border-box;
    gap: 16px;
    text-align: center;
    font-size: 16px;
    color: #b5b5b5;
    font-family: "DM Sans";
    z-index: 9999;
    flex-wrap: wrap;
    left: 20%;
}

.gmxWyU {
    display: flex;
    flex-direction: column;
    -webkit-box-pack: start;
    justify-content: flex-start;
    gap: 12px;
    /* min-height: 200px; */
}

.bIFEzi {
    -webkit-box-pack: justify;
    justify-content: space-between;
}

.eVSMfe {
    /* width: 100%; */
    display: flex;
    padding: 0px;
    -webkit-box-align: center;
    align-items: center;
    gap: 12px;
}

.dKubqp {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
}

.gTwleA {
    width: auto;
    padding: 4px;
    border: 1px solid rgba(255, 255, 255, 0.07);
    border-radius: 16px;
}

.cPCYrp {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: start;
    justify-content: flex-start;
}

.dznSOZ {
    width: auto;
    cursor: pointer;
    padding: 6px 12px;
    text-align: center;
    gap: 4px;
    border-radius: 12px;
    background: rgba(255, 255, 255, 0.07);
    pointer-events: none;
}

.fCURlt {
    width: auto;
    cursor: pointer;
    padding: 6px 12px;
    text-align: center;
    gap: 4px;
    border-radius: 12px;
    background: transparent;
}

.css-1urox24 {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    font-weight: 485;
    font-size: 16px;
}

.jwRPKK {
    color: rgb(255, 255, 255);
    letter-spacing: -0.01em;
}

.bkPaeK {
    padding: 8px 4px;
    border-radius: 12px;
    width: 100px;
    flex: 1 1 0%;
    border: 1px solid rgba(255, 255, 255, 0.07);
}

.bkPaeK input {
    color: rgb(255, 255, 255);
}

.eupJID {
    width: 100%;
    display: flex;
    flex: 1 1 0%;
    font-size: 16px;
    border: 0px;
    outline: none;
    background: transparent;
    text-align: right;
}

.red-color {
    color: red;
}

.css-142zc9n {
    box-sizing: border-box;
    position: relative;
    width: 100%;
    margin: 0px;
    min-width: 0px;
    font-weight: 485;
    font-size: 14px;
}

.hMbAQl {
    color: rgb(238, 179, 23);
    letter-spacing: -0.01em;
}

.dashboard-wrap {
    .dash-content-area {
        position: relative;
        z-index: 1;
        // @media only screen and (max-width: 767px) {
        //     padding-right: 0 !important;
        // }
        // &::after {
        //     position: absolute;
        //     content: "";
        //     left: 50%;
        //     top: 35%;
        //     width: 200px;
        //     height: 200px;
        //     background-color: #60BC82;
        //     transform: translateX(-50%);
        //     z-index: -2;
        //     filter: blur(160px);
        // }
        .swap-section {
            margin-bottom: 90px;
            .swap-sidebar {
                .swap-heading {
                    color: #D8DEED;
                    font-size: 32px;
                    font-weight: 500;
                    line-height: 150%;
                }
                .swap-setting {
                    --width: 32px;
                    width: var(--width);
                    height: var(--width);
                    padding: 8px;
                    background-color: #2C2C31;
                    border-radius: 50%;
                    border: 1.3px solid #42424A;
                    cursor: pointer;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
                .enpFfZ {
                    border-radius: 16px;
                    border: 1px solid #61C57B;
                    background: #2C2C31;
                    padding: 24px;
                    width: 491px;
                    @media only screen and (max-width: 767px) {
                        width: 100%;
                    }
                    .iUsjHb {
                        border-radius: 16px;
                        border: 1px solid #42424A;
                        background: linear-gradient(96deg, rgba(97, 197, 123, 0.10) 4.32%, rgba(89, 147, 164, 0.01) 100%);
                        .top-text {
                            margin: 8px 8px 10px 16px;
                            .dollarValue {
                                color: #A3A3A3;
                                font-size: 16px;
                                font-weight: 500;
                                line-height: 150%;
                            }
                            .max {
                                font-size: 14px;
                                color: #FFF;
                            }
                        }
                        .btn {
                            border-radius: 8px;
                            background: linear-gradient(93deg, #61C57B 1.7%, #5993A4 100%);
                            &:hover {
                                transform: translateY(0px);
                                color: #fff;
                            }
                        }
                        .select-item {
                            border-radius: 16px;
                            background: #201F24;
                            padding: 8px 10px;
                            .mony-value {
                                span {
                                    color: #F7FFFA;
                                    font-size: 18px;
                                    font-weight: 500;
                                    line-height: 150%;
                                    margin-bottom: 10px;
                                }
                                p {
                                    color: #81858F;
                                    font-size: 10px;
                                    font-weight: 400;
                                    line-height: 150%;
                                }
                            }
                        }
                    }
                    .btnb {
                        color: #EDF1F5;
                        font-size: 18px;
                        font-weight: 700;
                        line-height: 150%;
                        padding-top: 17px !important;
                        padding-bottom: 17px;
                        &:hover {
                            transform: rotate(0px);
                        }
                    }
                }
                .swip-btn {
                    --width: 48px;
                    height: var(--width);
                    width: var(--width);
                    border-radius: 50%;
                    border: 1.2px solid #42424A;
                    background: linear-gradient(114deg, #4C4C54 1.1%, #212226 100%);
                    padding: 14px;
                }
            }
        }
    }
    .form-select {
        border-radius: 10px;
        border: 1px solid #42424A;
        background: linear-gradient(96deg, rgba(97, 197, 123, 0.10) 4.32%, rgba(89, 147, 164, 0.01) 100%);
        padding: 10px;
        color: #D8DEED;
        font-size: 18px;
        font-weight: 700;
        line-height: 150%;
        width: 126px;
        svg {
            --width: 16;
            width: var(--width);
            height: var(--width);
            object-fit: contain;
            flex: 0 0 auto;
        }
    }
    .table-area {
        border-radius: 16px;
        // border: 1px solid #42424A;
        background: #2C2C31;
        padding:  6px 24px;
        margin-bottom: 60px;
        & th {
            white-space: nowrap;
            color: #F7FFFA;
        }
        th,
        td {
            color: #A3A3A3 !important;
            font-size: 14px !important;
            font-weight: 400 !important;
            line-height: 150%;
            padding: 14px 20px;
        }
        head,
        tr {
            border-radius: 8px;
            background: transparent !important;
            // display: flex;
            // justify-content: space-around;
            // border-spacing: 10px;
        }
        thead {
            & th {
                background-color: #42424A !important;
                &:first-child {
                    border-top-left-radius: 8px;
                    border-bottom-left-radius: 8px;
                }
                &:last-child {
                    border-top-right-radius: 8px;
                    border-bottom-right-radius: 8px;
                }
            }
        }
        tbody {
            & tr {
                margin-top: 10px;
            }
            & td {
                border: 1px solid transparent !important;
                border-top-color: #42424A !important;
                border-bottom-color: #42424A !important;
                &:first-child {
                    border-top-left-radius: 8px;
                    border-bottom-left-radius: 8px;
                    border-left-color: #42424A !important;
                }
                &:last-child {
                    border-top-right-radius: 8px;
                    border-bottom-right-radius: 8px;
                    border-right-color: #42424A !important;
                }
            }
        }
        .parpage {
            border-radius: 8px;
            border: 1px solid #42424A;
            p {
                margin-bottom: 0;
                color: #A3A3A3;
                font-size: 16px;
            }
            .css-194a1fa-MuiSelect-select-MuiInputBase-input.css-194a1fa-MuiSelect-select-MuiInputBase-input.css-194a1fa-MuiSelect-select-MuiInputBase-input {
                padding-right: 24px;
                min-width: 16px;
                color: #FFF;
            }
            svg {
                color: #fff;
            }
            .MuiTablePagination-displayedRows {
                color: #FFF;
            }
            .MuiTablePagination-actions {
                svg {
                    color: #A3A3A3;
                }
            }
        }
        .MuiPaper-root {
            background-color: transparent !important;
            box-shadow: 0 0 0 !important;
        }
    }
}

table {
    border-collapse: separate !important;
    border-spacing: 0 10px !important;
}