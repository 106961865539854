.theme-footer {
    @include xxxl {
        padding: 0;
    }
    padding: 0 50px;
    @include ssm {
        padding: 0 22px;
    }
    .footer-top {
        padding-top: 90px;
        padding-bottom: 50px;
        @include lg {
            padding-top: 64px;
            padding-bottom: 40px;
        }
        @include md {
            padding-top: 50px;
            padding-bottom: 0px;
        }
        @include sm {
            padding-top: 42px;
            padding-bottom: 0px;
        }
    }
}

.footer-bottom {
    padding-top: 25px;
    padding-bottom: 80px;
    @include lg {
        padding-top: 24px;
        padding-bottom: 60px;
    }
    @include md {
        padding-top: 0;
        padding-bottom: 48px;
    }
    @include sm {
        padding-top: 0;
        padding-bottom: 40px;
    }
}

.footer-logo {
    max-width: 220px;
    @media screen and (max-width: 575px) {
        max-width: 160px;
    }
}