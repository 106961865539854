.icon-square {
    height: 40px;
    width: 40px;
    @extend .d-flex;
    @extend .align-items-center;
    @extend .justify-content-center;
    font-size: 36px;
    @extend .flex-shrink-0;

    &.icon {
        &-circle {
            @extend .rounded-pill;
        }

        &-social {
            background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.1) 100%);
            backdrop-filter: blur(42.97px);
            @extend .link-light;

            &:hover {
                // @extend .bg-gradient;
                background-color: #CFCAD8;
            }
        }

        &-gradient {
            border: 2px solid #CFCAD8;
            color: #CFCAD8;
            opacity: .7;

            &:hover {
                // @extend .bg-gradient;
                background-color: #4E8664;

                @extend .text-white;
                border-width: 0;
                opacity: 1;
            }
        }

        &-outline {
            @extend .bg-gradient;
            @extend .position-relative;
            z-index: 1;

            &:after {
                content: "";
                position: absolute;
                top: 1px;
                left: 1px;
                height: calc(100% - 2px);
                width: calc(100% - 2px);
                background-color: #0B0B12;
                border-radius: inherit;
                z-index: -1;
            }

            &:hover:after {
                opacity: 0;
                visibility: hidden;
            }
        }
    }

    &.icon-user {
        background-color: #E4810B;
    }

    &.icon {
        &-xs {
            width: 10px;
            height: 10px;
        }

        &-16 {
            width: 16px;
            height: 16px;
        }

        &-sm {
            width: 30px;
            height: 30px;
        }

        &-md {
            width: 60px;
            height: 60px;

            @media screen and (max-width: 575px) {
                width: 50px;
                height: 50px;
            }
        }

        &-lg {
            width: 75px;
            height: 75px;

            @media screen and (max-width: 575px) {
                width: 60px;
                height: 60px;
            }
        }

        &-xl {
            width: 100px;
            height: 100px;

            @media screen and (max-width: 575px) {
                width: 80px;
                height: 80px;
            }
        }
    }
}

a.icon-circle.icon-social.icon-square svg {
    height: 16px !important;
}