@import './connect';
@import './navbar';
@import './sidebar';
@import './dashboard';
@import './sidebar';
.css-1r94d8e-MuiCardContent-root {
    @include sm {
        flex-wrap: wrap;
        padding: 0 !important;
        gap: 16px;
    }
}

.css-ahj2mt-MuiTypography-root {
    @include sm {
        flex: 0 0 auto;
        width: 100%;
    }
}