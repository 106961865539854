@import './settings';
@import './header';
@import './footer';
@import './backtotop';
.btn-primary {
    background: linear-gradient(149.3deg, #02DB5B -29.94%, #4977C1 135.03%);
    color: white;
}

.btn-outline {
    border-radius: "8px";
    border-color: #02DB5B;
    background-color: transparent;
    color: white;
}

.active {
    background-color: transparent;
    border-bottom: 2px;
    border-color: 325a98e;
}