.section-title {
    margin-bottom: 60px;
    @media screen and (max-width: 991px) {
        margin-bottom: 30px;
    }
    @include sm {
        margin-bottom: 16px;
    }
    .pre-title {
        text-transform: uppercase;
        letter-spacing: .05em;
        font-weight: 500;
        margin-bottom: 6px;
    }
    .main-title {
        font-size: 52px;
        font-weight: 600;
        line-height: 1.2;
        @include lg {
            font-size: 44px;
        }
        @include md {
            font-size: 38px;
        }
        @include sm {
            font-size: 26px;
        }
        @include ssm {
            font-size: 24px;
        }
    }
    .sub-title {
        margin-top: 20px;
        width: 50%;
        font-size: 30px;
        @extend .text-secondary;
        @media screen and (max-width: 991px) {
            font-size: 30px;
        }
        @media screen and (max-width: 575px) {
            font-size: 20px;
            margin-top: 14px;
        }
        @media screen and (max-width: 767px) {
            width: 100%;
        }
    }
    &.text-center {
        .sub-title {
            margin-left: auto;
            margin-right: auto;
        }
    }
    &.text-end {
        .sub-title {
            margin-left: auto;
        }
    }
    &.mb {
        &-sm {
            margin-bottom: 20px;
        }
        &-md {
            margin-bottom: 40px;
        }
        &-lg {
            margin-bottom: 80px;
        }
        &-xl {
            margin-bottom: 100px;
        }
    }
}

.theme-hero {
    .section-title {
        .sub-title {
            font-size: 22px;
        }
    }
}

.font-64 {
    font-size: 64px !important;
    @include xl {
        font-size: 55px !important;
    }
    @include lg {
        font-size: 50px !important;
    }
    @include mmd {
        font-size: 42px !important;
    }
    @include sm {
        font-size: 38px !important;
    }
    @include ssm {
        font-size: 34px !important;
    }
}

.theme-about {
    .section-title {
        & .main-title,
        & .pre-title {
            // font-size: 43px;
            @include xl {
                font-size: 55px !important;
            }
            @include lg {
                font-size: 50px !important;
            }
            @include mmd {
                font-size: 50px !important;
            }
            @include sm {
                font-size: 38px !important;
            }
        }
    }
    // background-color:#201F24 ;
    background-image: url('../../../img/regular/aboutusbg.svg');
    display: flex;
    flex-wrap: wrap;
}

.custom-g4 {
    @include lg {
        --bs-gutter-x: 1.5rem;
        --bs-gutter-y: 2rem;
    }
    @include mmd {
        --bs-gutter-x: 1.5rem;
        --bs-gutter-y: 2rem;
    }
}