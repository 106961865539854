//Trends product design 
.trendproduct-card {
    border: none;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    background-color: #2E2D31;
    text-align: center;
}

.box-wraper {
    border-radius: 16px;
    border: 1px solid #42424A;
    background: linear-gradient(114deg, #33333A 1.1%, #212226 100%);
    padding: 17px 16px;
}

.top-images {
    position: relative;
}

.thumbnail-img {
    position: relative;
    z-index: 1;
    overflow: hidden;
    padding-bottom: 38%;
    & img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 12px;
        border: 0.986px solid #42424A;
    }
}

.overlay-button {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 5;
    transform: translate(-50%, -50%);
    & img {
        max-width: 140px;
        width: 100%;
        border-radius: 8px;
        background: linear-gradient(90deg, rgba(83, 170, 106, 0.20) 0.18%, rgba(89, 148, 162, 0.20) 99.84%);
        box-shadow: 2px 2px 36px 0px rgba(97, 196, 123, 0.4);
        backdrop-filter: blur(4px);
        padding: 10px;
        border: 0.5px solid rgba(89, 147, 164, 0.6);
    }
}

.card-text {
    color: #A3A3A3 !important;
    text-align: center;
    font-family: "DM Sans";
    font-size: 16px !important;
    font-weight: 400;
}

.trendproduct-card-text {
    font-size: 0.9rem;
    color: #666;
    @media screen and (max-width: 991px) {
        font-size: 1.6rem;
    }
}

.trendproduct-button {
    border-radius: 8px !important;
    border: 0.3px #61C57B !important;
    background: linear-gradient(93deg, #61C57B 1.7%, #5993A4 100%) !important;
    padding: 8px;
    display: block;
    width: 100%;
    text-align: center;
    color: #FFF;
    font-family: "DM Sans";
    font-size: 16px !important;
    font-weight: 700;
    transform: translateY(0) !important;
    &:hover {
        color: #FFF;
        transform: scale(1.02) !important;
    }
}

.btn-primary {
    font-weight: bold;
    font-size: 0.9rem;
    padding: 0.5rem 1rem;
}